<template>
	<v-sheet class="customer" id="customer" style="height: calc(100vh - 88px)">
		<v-row>
			<v-col md="6" class="my-auto">
				<h1 class="custom-header-blue-text m-0">{{ pageTitle() }}</h1>
			</v-col>
			<v-col md="6" class="text-right">
				<v-btn
					v-if="stepper > 1"
					:loading="pageLoading"
					class="white--text mr-2"
					depressed
					color="blue darken-4"
					tile
					:disabled="pageLoading"
					v-on:click="goBackStepper()"
				>
					Back
				</v-btn>
				<v-btn
					:loading="pageLoading"
					class="white--text mr-2"
					depressed
					color="blue darken-4"
					tile
					:disabled="pageLoading || !formValid"
					v-on:click="updateOrCreate()"
				>
					Save
				</v-btn>
				<v-btn :disabled="pageLoading" depressed tile v-on:click="goBack()"> Cancel </v-btn>
			</v-col>
			<v-col md="12">
				<perfect-scrollbar
					:options="{ suppressScrollX: true }"
					class="scroll custom-box-top-inner-shadow"
					style="max-height: calc(100vh - 185px); position: relative"
				>
					<v-form
						ref="addressForm"
						v-model.trim="formValid"
						lazy-validation
						v-on:submit.stop.prevent="updateOrCreate()"
					>
						<v-row>
							<v-col md="8" class="pb-0">
								<AddressTemplate
									:loading="pageLoading"
									:disabled="pageLoading"
									v-model="address"
									:uuid="customerUuid"
									:type="2"
									:contact-person-list="contactPersonList"
									v-on:updateContactPersonList="getContactPersons"
								></AddressTemplate>
							</v-col>
						</v-row>
					</v-form>
				</perfect-scrollbar>
			</v-col>
		</v-row>
	</v-sheet>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import {
	CreateOrUpdateCustomerProperty,
	GetContactPersons,
	GetProperty,
} from "@/core/lib/contact.lib";
import AddressTemplate from "@/view/components/Address";
// import AutoCompleteInput from "@/view/components/AutoCompleteInput";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import ApiService from "@/core/services/api.service";

export default {
	name: "customer-create",
	title: "Create Customer",
	data() {
		return {
			uuid: null,
			barcode: null,
			formValid: true,
			designationLists: [],
			contactPersons: [],
			contactPersonList: [],
			customerUuid: null,
			customerProperty: [],
			customerId: 0,
			manageDesignationDialog: false,
			pageLoading: false,
			venderList: [
				{
					value: 1,
					name: "Name1",
					avatar: "https://pico.businessthrust.com//media/misc/no_photo_found.png",
				},
				{
					value: 2,
					name: "Name2",
					avatar: "https://pico.businessthrust.com//media/misc/no_photo_found.png",
				},
				{
					value: 3,
					name: "Name3",
					avatar: "https://pico.businessthrust.com//media/misc/no_photo_found.png",
				},
			],
			vender: 1,
			stepper: 1,
			address: {
				id: null,
				uuid: null,
				name: null,
				id_number: null,
				location: null,
				latitude: null,
				longitude: null,
				address_line_1: null,
				address_line_2: null,
				address_city: null,
				address_state: null,
				address_country: "Singapore",
				address_postal_code: null,
				description: null,
				address_type: 1,
				has_lat_long: 0,
				customer_property: null,
				customerId: 0,
				nest_location: null,
			},
		};
	},
	components: {
		AddressTemplate,
		// AutoCompleteInput,
	},
	methods: {
		getCustomerProperty() {
			ApiService.setHeader();
			ApiService.get(`customer-property/${this.customerId}`)
				.then(({ data }) => {
					this.customerProperty = data;
				})
				.catch(() => {});
		},
		async updateOrCreate() {
			const _this = this;
			const formErrors = _this.validateForm(_this.$refs.addressForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
				return false;
			}

			if (!_this.$refs.addressForm.validate()) {
				return false;
			}
			try {
				_this.pageLoading = true;
				this.address.address_type = 2;
				CreateOrUpdateCustomerProperty(this.customerUuid, { address: [this.address] })
					.then(() => {
						_this.$store.commit(SET_MESSAGE, [
							{ model: true, message: "Success ! Property has been saved." },
						]);
						this.$router.push({ name: "property" });
					})
					.catch((error) => {
						_this.logError(error);
					})
					.finally(() => {
						_this.pageLoading = false;
					});
			} catch (error) {
				_this.logError(error);
			} finally {
				_this.pageLoading = false;
			}
		},
		pageTitle() {
			if (this.uuid) {
				return "Update Property - " + this.barcode;
			}
			return "Create new Property";
		},
		getContactPersons() {
			const _this = this;
			GetContactPersons(this.customerUuid)
				.then((data) => {
					_this.contactPersonList = data;
				})
				.catch((error) => {
					_this.goBack();
					_this.logError(error);
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		getProperty() {
			const _this = this;
			GetProperty(this.uuid, this.id)
				.then((data) => {
					_this.address = data;
					_this.address.contact_person = data.contact_person.id;
					_this.customerUuid = data.contact.uuid;
					_this.customerId = data.contact.id;
					_this.getCustomerProperty();
					_this.$store.dispatch(SET_BREADCRUMB, [
						{ text: "Property", disabled: true },
						{ text: "Update", disabled: true },
					]);
					_this.barcode = data.barcode;
					_this.getContactPersons();
				})
				.catch((error) => {
					_this.goBack();
					_this.logError(error);
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
	},
	computed: {
		...mapGetters(["errors"]),
	},
	mounted() {
		if (this.$route.query.customer) {
			this.customerUuid = this.$route.query.customer;
			this.customerId = this.$route.query.customerId;
			this.getContactPersons();
			this.getCustomerProperty();
		}
		this.$store.dispatch(SET_BREADCRUMB, [
			{ text: "Property", disabled: true },
			{ text: "Create", disabled: true },
		]);

		const { name, params } = this.$route;
		if (name === "property-update") {
			this.uuid = params.uuid;
			if (!this.uuid) {
				this.goBack();
			}
			this.pageLoading = true;
			this.getProperty();
		}
	},
	beforeDestroy() {
		this.$store.dispatch(SET_BREADCRUMB, []);
	},
};
</script>
